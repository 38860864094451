import React from 'react';
import {
  AddCustomer,
  AddDevice,
  AddUser,
  Alerts,
  CustomerList,
  Dashboard,
  Device,
  LocationSummaryList,
  Login,
  Measurements,
  MyProfile,
  NewPassword,
  NotFound,
  Password,
  ResetPassword,
  Resume,
  StyleGuide,
  UsersList,
  LocationsList,
  ReportsScreen,
  ReportMonthScreen,
  LocatorsList,
  ClientsList,
  AssignLocator,
  AddClient,
  AddLocator,
  Onboarding,
  Terms,
  ExpiredToken,
  LocatorDashboard,
  MainLocatorView,
  FlatView,
  Settings,
  LocatorPassword,
} from '@screens';
import { Route, Routes } from 'react-router-dom';
import { LOCATOR_PATH, PATH } from '@router';

import { RequireAuth } from './RequireAuth';
import { RequireAdmin } from './RequireAdmin';
import { RequireClient } from './RequireClient';
import { PreventLocator } from './PreventLocator';

export const Router: React.FC = () => {
  return (
    <Routes>
      <Route path={PATH.LOGIN} element={<Login />} />
      <Route path={PATH.RESET_PASSWORD} element={<ResetPassword />} />
      <Route path={PATH.NEW_PASSWORD} element={<NewPassword />} />
      <Route path={PATH.ONBOARDING} element={<Onboarding />} />
      <Route path={PATH.TERMS} element={<Terms />} />
      <Route path={PATH.EXPIRED_TOKEN} element={<ExpiredToken />} />
      <Route
        path={LOCATOR_PATH.LOCATOR_DASHBOARD}
        element={
          <RequireAuth>
            <LocatorDashboard />
          </RequireAuth>
        }
      >
        <Route path={LOCATOR_PATH.MAIN} element={<MainLocatorView />} />
        <Route path={LOCATOR_PATH.FLAT_VIEW} element={<FlatView />} />
        <Route path={LOCATOR_PATH.SETTINGS} element={<Settings />} />
        <Route path={LOCATOR_PATH.PASSWORD} element={<LocatorPassword />} />
        <Route
          path={LOCATOR_PATH.DEVICE}
          element={
            <div style={{ marginTop: -40 }}>
              <Device />
            </div>
          }
        >
          <Route path={LOCATOR_PATH.DEVICE_DETAILS_OVERVIEW} element={<Resume />} />
          <Route path={LOCATOR_PATH.DEVICE_DETAILS_ALERTS} element={<Alerts />} />
          <Route path={LOCATOR_PATH.DEVICE_DETAILS_MEASUREMENTS} element={<Measurements />} />
        </Route>
      </Route>
      <Route
        path={PATH.DASHBOARD}
        element={
          <RequireAuth>
            <PreventLocator>
              <Dashboard />
            </PreventLocator>
          </RequireAuth>
        }
      >
        <Route path={`${PATH.REPORTS}`} element={<ReportsScreen />}>
          <Route path={`${PATH.MONTHLY_REPORT}`} element={<ReportMonthScreen />} />
        </Route>

        <Route path={PATH.STYLEGUIDE} element={<StyleGuide />} />
        <Route path={PATH.PANEL} element={<LocationSummaryList />} />
        <Route path={PATH.DEVICE} element={<Device />}>
          <Route path={PATH.DEVICE_DETAILS_OVERVIEW} element={<Resume />} />
          <Route path={PATH.DEVICE_DETAILS_ALERTS} element={<Alerts />} />
          <Route path={PATH.DEVICE_DETAILS_MEASUREMENTS} element={<Measurements />} />
        </Route>
        <Route
          path={PATH.ADD_DEVICE}
          element={
            <RequireAdmin>
              <AddDevice />
            </RequireAdmin>
          }
        />
        <Route
          path={PATH.ADMIN_LIST}
          element={
            <RequireAdmin>
              <UsersList />
            </RequireAdmin>
          }
        />
        <Route
          path={PATH.ADD_USER}
          element={
            <RequireAdmin>
              <AddUser />
            </RequireAdmin>
          }
        />
        <Route path={PATH.MY_PROFILE} element={<MyProfile />} />
        <Route path={PATH.PASSWORD} element={<Password />} />
        <Route
          path={PATH.CUSTOMER_LIST}
          element={
            <RequireAdmin>
              <CustomerList />
            </RequireAdmin>
          }
        />
        <Route
          path={PATH.LOCATIONS_LIST}
          element={
            <RequireAdmin>
              <LocationsList />
            </RequireAdmin>
          }
        />
        <Route
          path={PATH.ADD_CUSTOMER}
          element={
            <RequireAdmin>
              <AddCustomer />
            </RequireAdmin>
          }
        />
        <Route
          path={PATH.LOCATORS_LIST}
          element={
            <RequireClient>
              <LocatorsList />
            </RequireClient>
          }
        />
        <Route
          path={PATH.ADD_LOCATOR}
          element={
            <RequireClient>
              <AddLocator />
            </RequireClient>
          }
        />
        <Route
          path={PATH.ASSIGN_LOCATIONS}
          element={
            <RequireClient>
              <AssignLocator />
            </RequireClient>
          }
        />
        <Route
          path={PATH.CLIENTS_LIST}
          element={
            <RequireClient>
              <ClientsList />
            </RequireClient>
          }
        />
        <Route
          path={PATH.ADD_CLIENT}
          element={
            <RequireAdmin>
              <AddClient />
            </RequireAdmin>
          }
        />
      </Route>
      <Route path={PATH.NOT_FOUND} element={<NotFound />} />; ;
    </Routes>
  );
};
