import { FlatItem } from './flat-item';
import { Box } from '@mui/material';

import { useStyles } from './flat-item.styles';

interface IFlatItem {
  address: string;
  id: string;
}

interface IFlatList {
  list: IFlatItem[];
}

export const FlatList = ({ list }: IFlatList) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.list}>
      {list.map((item) => (
        <FlatItem key={item.id} address={item.address} id={item.id} />
      ))}
    </Box>
  );
};
