import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';

type TAssignedLocation = {
  id: string;
  address: string;
};

export interface IAssignedLocationsResponse {
  limit: number;
  offset: number;
  totalSize: number;
  results: TAssignedLocation[];
}

export const getAssignedLocations = (): Promise<AxiosResponse<IAssignedLocationsResponse>> => {
  return httpsService.get(Endpoint.LOCATIONS_ASSIGNED);
};
