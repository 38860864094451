import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';

import { useStyles } from './user.styles';

interface IUserProps {
  name: string;
  surname: string;
}

export const User = ({ name, surname }: IUserProps) => {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      <PersonOutlineRoundedIcon sx={{ color: '#1C1B1F' }} />
      {name} {surname}
    </div>
  );
};
