import { Typography } from '@mui/material';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { useNavigate } from 'react-router-dom';
import { LOCATOR_PATH } from '@router';

import { useStyles } from './flat-item.styles';

interface FlatItemProps {
  address: string;
  id: string;
}

export const FlatItem = ({ address, id }: FlatItemProps) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const navigateToFlatDetails = () => {
    navigate(`/${LOCATOR_PATH.LOCATOR_DASHBOARD}/${LOCATOR_PATH.FLAT_VIEW.replace(':flatId', id)}`);
  };

  return (
    <div className={classes.container} id={id}>
      <div>
        <Typography variant="h6">{address}</Typography>
      </div>
      <div className={classes.itemFooter} onClick={navigateToFlatDetails}>
        <Typography color="#025491" variant="h6">
          Szczegóły
        </Typography>
        <ArrowForwardRoundedIcon sx={{ color: '#025491' }} />
      </div>
    </div>
  );
};
