import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Button, Box, Typography, useTheme } from '@mui/material';
import { Input } from '@components';
import { SnackbarContext, UserContext } from '@contexts';
import { useContext } from 'react';
import updateMyUserData from 'src/api/updateMyData';
import { useNavigate } from 'react-router-dom';
import { LOCATOR_PATH, PATH } from '@router';

interface IFormInput {
  name: string;
  surname: string;
  email: string;
}

const profileValidationSchema = yup.object().shape({
  name: yup.string().max(255).required('Pole obowiązkowe'),
  surname: yup.string().max(255).required('Pole obowiązkowe'),
  email: yup.string().email('Niepoprawny e-mail').required('Pole obowiązkowe'),
});

export const ProfileForm: React.FC = (): JSX.Element => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { userState, userDispatch } = useContext(UserContext);
  const { snackbarDispatch } = useContext(SnackbarContext);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm<IFormInput>({
    mode: 'onBlur',
    defaultValues: {
      name: userState.name || '',
      surname: userState.surname || '',
      email: userState.email || '',
    },
    resolver: yupResolver(profileValidationSchema),
  });

  const onSubmit: SubmitHandler<IFormInput> = async ({ name, surname, email }) => {
    if (isValid) {
      updateMyUserData({
        snackbarDispatch,
        userDispatch,
        newUser: {
          ...userState,
          name,
          surname,
          email,
        },
      });
    }

    reset({
      name,
      surname,
      email,
    });
  };

  const navigateToPassword = () => {
    if (userState.roles === 'Locator') {
      navigate(`/${LOCATOR_PATH.LOCATOR_DASHBOARD}/${LOCATOR_PATH.PASSWORD}`);
    } else {
      navigate(`/${PATH.DASHBOARD}/${PATH.PASSWORD}`);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="false">
      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '20px' }}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              error={!!errors.name}
              helperText={errors.name && errors.name?.message && errors.name.message}
              label="Imię"
              ref={null}
              disabled={userState.roles !== 'Administrator'}
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: theme.palette.grey[userState.roles === 'Locator' ? 500 : 800],
                },
              }}
            />
          )}
        />
        <Controller
          name="surname"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              error={!!errors.surname}
              helperText={errors.surname && errors.surname?.message && errors.surname.message}
              label="Nazwisko"
              ref={null}
              disabled={userState.roles !== 'Administrator'}
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: theme.palette.grey[userState.roles === 'Locator' ? 500 : 800],
                },
              }}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              error={!!errors.email}
              helperText={errors.email && errors.email?.message && errors.email.message}
              label="E-mail"
              ref={null}
              disabled={userState.roles === 'Locator'}
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: theme.palette.grey[userState.roles === 'Locator' ? 500 : 800],
                },
              }}
            />
          )}
        />
        {userState.roles !== 'Locator' && (
          <Input
            label="Rola"
            value={
              userState.roles === 'Locator' ? 'Lokator' : userState.roles === 'Client' ? 'Klient' : userState.roles
            }
            ref={null}
            disabled={true}
            sx={{
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: theme.palette.grey[userState.roles === 'Locator' ? 500 : 800],
              },
            }}
          />
        )}

        {userState.roles !== 'Locator' && (
          <Button
            variant="contained"
            sx={{
              borderRadius: '8px',
            }}
            disabled={!isDirty || !isValid}
            type="submit"
          >
            <Typography> Aktualizuj profil </Typography>
          </Button>
        )}
        <Button
          variant="contained"
          sx={{
            borderRadius: '8px',
            padding: 1,
          }}
          onClick={navigateToPassword}
        >
          <Typography> Zmień hasło </Typography>
        </Button>
      </Box>
    </form>
  );
};
