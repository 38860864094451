import { Navigate, useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { SnackbarActionType, SnackbarContext, SnackbarType, UserContext } from '@contexts';
import { LOCATOR_PATH } from '@router';

export const PreventLocator = ({ children }: { children: JSX.Element }) => {
  const { userState } = useContext(UserContext);
  const { snackbarDispatch } = useContext(SnackbarContext);
  const location = useLocation();

  if (userState.roles !== 'Locator') return children;
  else {
    snackbarDispatch({
      type: SnackbarActionType.SHOW,
      payload: {
        message: 'Brak dostępu do zasobu',
        type: SnackbarType.INFO,
      },
    });
    return (
      <Navigate to={`/${LOCATOR_PATH.LOCATOR_DASHBOARD}/${LOCATOR_PATH.MAIN}`} state={{ from: location }} replace />
    );
  }
};
