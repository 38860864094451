import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';

export interface ILocationMetersDataResponse {
  id: string;
  serialNumber: string;
  state: 'Ok' | 'Alert';
  measuredUtility: 'ColdWater' | 'HotWater' | 'Heat';
  reading: {
    date: string;
    value: number;
    unit: string;
  };
}

interface ILocationMetersDataParams {
  locationId: string;
  measuredUtility?: 'Water' | 'Heat';
  onlyAlerts: boolean;
}

export const getLocationMetersData = ({
  locationId,
  measuredUtility,
  onlyAlerts,
}: ILocationMetersDataParams): Promise<AxiosResponse<ILocationMetersDataResponse[]>> => {
  return httpsService.get(
    Endpoint.LOCATOR_LOCATION_DASHBOARD_METERS.replace(':locationId', locationId)
      .replace(':measuredUtility', measuredUtility || '')
      .replace(':onlyAlerts', onlyAlerts.toString() || '')
  );
};
