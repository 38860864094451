import { httpsService } from '@services';
import { Endpoint } from '@api';
import { AxiosResponse } from 'axios';

export interface ILocationOverviewResponse {
  id: string;
  address: string;
  coldWaterMetersReadingSum: number;
  hotWaterMetersReadingSum: number;
  heatMetersReadingSum: number;
}

export const getLocationOverview = ({
  locationId,
}: {
  locationId: string;
}): Promise<AxiosResponse<ILocationOverviewResponse>> => {
  return httpsService.get(Endpoint.LOCATOR_LOCATION_DASHBOARD_OVERVIEW.replace(':locationId', locationId));
};
