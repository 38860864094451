import { useQuery } from 'react-query';

import { getAssignedLocations } from '../infrastructure/get-assigned-locations';

export const useGetAssignedLocations = () => {
  return useQuery({
    queryFn: () => getAssignedLocations(),
    queryKey: ['assigned-locations'],
    cacheTime: 0,
    staleTime: 0,
  });
};
