import { httpsService } from '@services';
import { Endpoint } from '@api';

type MeasurementType = 'Heat' | 'ColdWater' | 'HotWater';

export interface IReportProps {
  cityId?: string;
  streetId?: string;
  buildingId?: string;
  flatId?: string;
  locationId?: string;
  measurementTypes?: MeasurementType[];
  forDay?: number;
}

export const getGeneralReport = ({
  cityId,
  streetId,
  buildingId,
  flatId,
  measurementTypes,
  forDay,
  locationId,
}: IReportProps) => {
  return httpsService.post(
    Endpoint.GENERAL_REPORT,
    {
      cityId,
      streetId,
      buildingId,
      flatId,
      measurementTypes,
      forDay,
      locationId,
    },
    {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};
