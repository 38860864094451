import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  flatView: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    marginTop: 16,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '100%',
  },
  reportContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: 600,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  dataGrid: {
    width: '100%',
    height: 400,
    marginTop: 16,
  },
  icon: {
    '&.MuiSvgIcon-root': {
      transform: 'rotate(90deg)',
      color: '#101828',
    },
  },
}));
