import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  measuredUtilityCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    gap: 16,
  },
  icon: {
    '&.MuiSvgIcon-root': {
      transform: 'rotate(90deg)',
      color: 'orange',
    },
  },
}));
