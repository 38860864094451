import { useQuery } from 'react-query';

import { getLocationMetersData } from '../infrastructure/get-location-meters-data';

interface ILocationMetersData {
  locationId: string;
  measuredUtility?: 'Water' | 'Heat';
  onlyAlerts?: boolean;
}

export const useGetLocationMetersData = ({ locationId, measuredUtility, onlyAlerts = false }: ILocationMetersData) => {
  return useQuery({
    queryFn: () => getLocationMetersData({ locationId, measuredUtility, onlyAlerts }),
    queryKey: ['location-meters-data', locationId, measuredUtility, onlyAlerts],
    cacheTime: 0,
    staleTime: 0,
  });
};
