import { Row } from '@components';
import { FormControl, styled } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

const InputsContainer = styled(Row)(({ theme }) => ({
  gap: 8,
  flexWrap: 'wrap',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    justifyContent: 'flex-start',
    margin: '0 auto',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    rowGap: 10,
  },
}));

const SelectWrapper = styled(FormControl)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginLeft: 10,
    marginRight: 10,
  },
}));

const SwitcherWrapper = styled(FormControl)(({ theme }) => ({
  display: 'flex',
  alignSelf: 'center',
  marginLeft: '10px',
}));

export const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    padding: '13.5px 12px',
  },
}));

export { SelectWrapper, InputsContainer, SwitcherWrapper };
