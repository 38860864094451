import { FormInputProps, LoginResponseSuccessProps } from '@forms';
import { httpsService } from '@services';
import { Endpoint } from '@api';
import { UserAction, UserActionType, SnackbarAction, SnackbarActionType, SnackbarType } from '@contexts';
import { HttpError } from '@services';
import { NavigateFunction } from 'react-router-dom';
import getMyUserData from 'src/api/getMyUserData';
import { LOCATOR_PATH, PATH } from '@router';

interface LoginProps extends FormInputProps {
  userDispatch: UserAction;
  snackbarDispatch: SnackbarAction;
  navigate: NavigateFunction;
}

const login = async ({ email, password, userDispatch, snackbarDispatch, navigate }: LoginProps) => {
  try {
    const loginResult = await httpsService.post<LoginResponseSuccessProps>(Endpoint.LOGIN, {
      email,
      password,
    });

    if (loginResult.status === 200) {
      userDispatch({
        type: UserActionType.LOG_IN,
        payload: {
          accessToken: loginResult.data.accessToken,
          expires: loginResult.data.expires,
          id: loginResult.data.id,
        },
      });
      getMyUserData({ userDispatch, navigate }).then((data) => {
        if (data?.roles[0] === 'Locator') {
          navigate(`${LOCATOR_PATH.LOCATOR_DASHBOARD}/${LOCATOR_PATH.MAIN}`, { replace: true });
        } else {
          navigate(`${PATH.DASHBOARD}/${PATH.PANEL}`, { replace: true });
        }
      });
      snackbarDispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message: 'Zalogowano',
          type: SnackbarType.SUCCESS,
        },
      });
    }
  } catch (err) {
    if (err) {
      const axiosError = err as HttpError;
      if (axiosError.response?.data.title)
        snackbarDispatch({
          type: SnackbarActionType.SHOW,
          payload: {
            message: axiosError.response?.data.title,
            type: SnackbarType.ERROR,
          },
        });
    }
  }
};

export default login;
