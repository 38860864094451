export enum LOCATOR_PATH {
  LOCATOR_DASHBOARD = 'locator-dashboard',
  MAIN = 'main',
  FLAT_VIEW = 'flat-view/:flatId',
  SETTINGS = 'settings',
  PASSWORD = 'password',
  DEVICE = 'zarzadzanie/:id/:type',
  DEVICE_DETAILS_OVERVIEW = `informacje-ogolne`,
  DEVICE_DETAILS_ALERTS = 'alerty',
  DEVICE_DETAILS_MEASUREMENTS = 'pomiary',
}
