import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    borderRadius: '12px',
    height: '100%',
    width: '100%',
    border: '1px solid',
    borderColor: '#DEE2E6',
    padding: 16,
  },
  subtitle: {
    color: '#919EAB',
  },
  section: {
    color: '#101828',
  },
}));
