import { useQuery } from 'react-query';

import { getLocationOverview } from '../infrastructure/get-location-overview';

interface ILocationOverview {
  locationId: string;
}

export const useGetLocationOverview = ({ locationId }: ILocationOverview) => {
  return useQuery({
    queryFn: () => getLocationOverview({ locationId }),
    queryKey: ['location-overview', locationId],
    cacheTime: 0,
    staleTime: 0,
  });
};
