import { LOCATOR_PATH } from '@router';
import { useGetAssignedLocations } from '@api';
import { Typography } from '@mui/material';
import { SkeletonLoader } from '@components';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useStyles } from './main.styles';
import { FlatList } from '../flat-list/flat-list';

export const MainLocatorView = () => {
  const { classes } = useStyles();
  const { data, isLoading, isFetching, isError } = useGetAssignedLocations();
  const navigate = useNavigate();

  useEffect(() => {
    if (data && data?.data.results.length === 1) {
      navigate(
        `/${LOCATOR_PATH.LOCATOR_DASHBOARD}/${LOCATOR_PATH.FLAT_VIEW.replace(':flatId', data?.data.results[0].id)}`
      );
    }
  }, [data, navigate]);

  if (isError) {
    return (
      <div className={classes.container}>
        <div style={{ marginBottom: 32 }}>
          <Typography variant="h3">
            Wystąpił błąd podczas pobierania danych, spróbuj jeszcze raz lub skontaktuj się z Administratorem
          </Typography>
        </div>
      </div>
    );
  }

  if (data?.data.results.length === 0) {
    return (
      <div className={classes.container}>
        <div style={{ marginBottom: 32 }}>
          <Typography variant="h3"> Brak przypisanych mieszkań </Typography>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div style={{ marginBottom: 32 }}>
        <Typography variant="h3"> Lista mieszkań </Typography>
      </div>
      <div>
        {isLoading || isFetching ? <SkeletonLoader loadersCount={5} /> : data && <FlatList list={data?.data.results} />}
      </div>
    </div>
  );
};
