import { Typography } from '@mui/material';
import React from 'react';

import { useStyles } from './password.styles';
import { ChangePassword } from '../../../Dashboard/MyProfile/Password/ChangePassword.form';

export const Password = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.title} variant="h2">
        Zmiana hasła
      </Typography>
      <div className={classes.formWrapper}>
        <ChangePassword />
      </div>
    </div>
  );
};
