import { GridColDef } from '@mui/x-data-grid';
import 'moment/locale/pl';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import Chip from '@mui/material/Chip';
import WaterDropOutlinedIcon from '@mui/icons-material/WaterDropOutlined';
import { Typography } from '@mui/material';
import WaterOutlinedIcon from '@mui/icons-material/WaterOutlined';
import moment from 'moment/moment';

import { useStyles } from './data-grid.styles';

interface IMeasurementDetails {
  navigateToDetails: (id: string, type: 'water' | 'heater') => void;
}

export const MeasurementDetailsColumns = ({ navigateToDetails }: IMeasurementDetails): GridColDef[] => {
  const { classes } = useStyles();
  return [
    {
      field: 'serialNumber',
      headerName: 'ID',
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      width: 100,
    },
    {
      field: 'date',
      headerName: 'Data',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      sortable: false,
      valueGetter: (params) => String(moment.utc(params.row.reading.date).local().format('lll')),
    },
    {
      field: 'value',
      headerName: 'Stan licznika',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row?.reading?.value,
      renderCell: (params) => {
        return (
          <div>
            {params.row?.reading?.value} {params.row?.reading?.unit}
          </div>
        );
      },
    },
    {
      field: 'measuredUtility',
      headerName: 'Wielkość mierzona',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.row?.measuredUtility,
      renderCell: (params) => {
        return (
          <div>
            {params.row?.measuredUtility === 'HotWater' ? (
              <div className={classes.measuredUtilityCell}>
                <WaterDropOutlinedIcon sx={{ color: '#B72136' }} />
                <Typography variant="body1"> Woda ciepła </Typography>
              </div>
            ) : params.row?.measuredUtility === 'ColdWater' ? (
              <div className={classes.measuredUtilityCell}>
                <WaterDropOutlinedIcon sx={{ color: '#025491' }} />
                <Typography variant="body1"> Woda zimna </Typography>
              </div>
            ) : params.row?.measuredUtility === 'Heat' ? (
              <div className={classes.measuredUtilityCell}>
                <WaterOutlinedIcon className={classes.icon} />
                <Typography variant="body1"> Ogrzewanie </Typography>
              </div>
            ) : (
              <div>Brak</div>
            )}
          </div>
        );
      },
    },
    {
      field: 'state',
      headerName: 'Status',
      headerAlign: 'left',
      align: 'center',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            {params.row?.state === 'Ok' ? (
              <Chip sx={{ padding: 2, color: '#31AA27', backgroundColor: '#EBF6EB' }} label={'Brak'} />
            ) : (
              <Chip sx={{ padding: 2, color: '#FF8622', backgroundColor: '#FFF4E4' }} color="error" label={'Alert'} />
            )}
          </div>
        );
      },
    },
    {
      field: 'more',
      headerName: 'Więcej',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <div
            style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', gap: 8 }}
            onClick={() => navigateToDetails(params.row.id, params.row.measuredUtility === 'Heat' ? 'heater' : 'water')}
          >
            <ArrowOutwardIcon />
            Szczegóły
          </div>
        );
      },
    },
  ];
};
