import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '16px',
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  leftContainer: {
    display: 'flex',
    alignContent: 'center',
    flexDirection: 'row',
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  flatList: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.15000000596046448px',
    textAlign: 'left',
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
    color: '#475467',
  },
  menu: {
    '& .MuiMenu-list': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  menuItem: {
    width: 304,
    padding: 12,
    gap: 8,
  },
}));
