import { DeviceDetailsProps } from '@api/types';
import { createContext, Dispatch, Reducer } from 'react';
import { DeviceActionType } from './types';

type Action =
  | {
      type: DeviceActionType.SET_DEVICE;
      payload: { device: DeviceDetailsProps };
    }
  | {
      type: DeviceActionType.CLEAR_DEVICE;
    };

type DeviceAction = Dispatch<Action>;

interface DeviceContextProps {
  device: DeviceDetailsProps;
  deviceDispatch: DeviceAction;
}

const initialState: DeviceDetailsProps = {
  id: '',
  name: '',
  description: '',
  type: '',
  alert: undefined,
  localisation: undefined,
  lastMeasurement: undefined,
  messageGenerationPeriod: '',
  parameters: undefined,
  measurementPeriod: '',
  expirationDate: null,
  legalizationEndDate: null,
  radioParameters: undefined,
  installationAddress: '',
};

const DeviceContext = createContext<DeviceContextProps>({
  device: initialState,
  deviceDispatch: () => initialState,
});

const deviceReducer: Reducer<DeviceDetailsProps, Action> = (state, action) => {
  console.log('Action', action.type);

  switch (action.type) {
    case DeviceActionType.SET_DEVICE: {
      const { device } = action.payload;
      return {
        ...state,
        id: device.id,
        name: device.name,
        description: device.description,
        type: device.type,
        alert: device.alert,
        localisation: device.localisation,
        lastMeasurement: device.lastMeasurement,
        messageGenerationPeriod: device.messageGenerationPeriod,
        parameters: device.parameters,
        measurementPeriod: device.measurementPeriod,
        expirationDate: device.expirationDate,
        legalizationEndDate: device.legalizationEndDate,
        radioParameters: device.radioParameters,
        installationAddress: device.installationAddress,
      };
    }
    case DeviceActionType.CLEAR_DEVICE: {
      return {
        ...state,
        id: '',
        name: '',
        description: '',
        type: '',
        alert: undefined,
        localisation: undefined,
        lastMeasurement: undefined,
        messageGenerationPeriod: '',
        parameters: undefined,
        measurementPeriod: '',
        expirationDate: null,
        legalizationEndDate: null,
        radioParameters: undefined,
        installationAddress: '',
      };
    }
  }
};

export { deviceReducer, DeviceContext, initialState };
export { DeviceAction };
