import { Endpoint } from '@api';
import { UserAction, UserActionType } from '@contexts';
import { httpsService } from '@services';
import { UserProps } from 'src/contexts/UserContext';
import { LocalStorageKey } from '@api/types';
import { NavigateFunction } from 'react-router-dom';

interface GetUserDataProps {
  userDispatch: UserAction;
  navigate: NavigateFunction;
}

const getMyUserData = async ({ userDispatch, navigate }: GetUserDataProps) => {
  try {
    const { data, status } = await httpsService.get<UserProps>(Endpoint.MY_USER_DATA);

    if (status === 200) {
      userDispatch({
        type: UserActionType.GET_USER_DATA,
        payload: {
          id: data.id,
          name: data.name,
          surname: data.surname,
          email: data.email,
          roles: data.roles[0],
          currentCustomer: data.currentCustomer,
          termsAccepted: data.termsAccepted,
        },
      });
      return data;
    }
  } catch (err: any) {
    if (err?.response.status === 403) {
      window.localStorage.removeItem(LocalStorageKey.CURRENT_CUSTOMER);
      getMyUserData({ userDispatch, navigate });
      return;
    }
  }
};

export default getMyUserData;
