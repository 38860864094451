import { Typography } from '@mui/material';

import { useStyles } from './settings.styles';
import { ProfileForm } from '../../Dashboard/MyProfile/Profile/MyProfile.form';

export const Settings = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.title} variant="h2">
        Ustawienia konta
      </Typography>
      <Typography className={classes.subtitle} variant="body1">
        Jeśli widzisz bład w danych osobistych skontaktuj się z administratorem
      </Typography>
      <div className={classes.formWrapper}>
        <ProfileForm />
      </div>
    </div>
  );
};
