import { useAcceptTerms } from '@api';
import { useNavigate } from 'react-router-dom';
import { PATH } from '@router';
import { UserContext } from '@contexts';
import React, { useContext } from 'react';
import Parser from 'html-react-parser';
import { Checkbox, FormControlLabel } from '@mui/material';

import getMyUserData from '../../api/getMyUserData';
import { html } from './doc';
import { Logo } from '../Dashboard/Menu/MenuContainer/MenuOptions/components/logo';
import { useStyles } from './terms.styles';
import { Button, Typography } from '@mui/material';

export const Terms = () => {
  const { mutateAsync: acceptTerms } = useAcceptTerms();
  const navigate = useNavigate();
  const { userDispatch } = useContext(UserContext);
  const { classes } = useStyles();
  const [termsAccepted, setTermsAccepted] = React.useState(false);

  const toggleTermsAccepted = () => {
    setTermsAccepted((prevState) => !prevState);
  };

  const handleAccept = () => {
    acceptTerms({})
      .then(() => {
        getMyUserData({ userDispatch, navigate }).then(() => {
          navigate(PATH.LOGIN, { replace: true });
        });
      })
      .catch((e) => {
        console.log('error', e);
      });
  };

  return (
    <div className={classes.container}>
      <div>
        <Logo />
        <h1 className={classes.header}>Warunki korzystania z Wodmar Systems</h1>
      </div>
      <div>{Parser(html)}</div>
      <div></div>
      <div className={classes.button}>
        <FormControlLabel
          checked={termsAccepted}
          control={<Checkbox onClick={toggleTermsAccepted} />}
          label="Akceptuję warunki korzystania z wodmar-systems.pl"
        />
        <Button disabled={!termsAccepted} variant="contained" onClick={handleAccept}>
          <Typography variant="button" color="white">
            Zatwierdź
          </Typography>
        </Button>
      </div>
    </div>
  );
};
