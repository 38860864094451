import { Button, TextField, Tooltip, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { useGeneralReport } from '@api/reports';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { MeasurementType } from '@api/types';
import { SnackbarActionType, SnackbarContext, SnackbarType } from '@contexts';

import { useStyles } from './report-form.styles';

interface IFormInput {
  hotWater: boolean;
  coldWater: boolean;
  heat: boolean;
  forDay: number;
}

const reportSchema = yup.object().shape({
  hotWater: yup.boolean(),
  coldWater: yup.boolean(),
  heat: yup.boolean(),
  forDay: yup
    .number()
    .typeError('Wprowadź dzień miesiąca')
    .min(1, 'Minialny dzień miesiąca to 1')
    .max(31, 'Maksymalny dzień miesiąca to 31')
    .required('Pole wymagane'),
});

const createMeasurementType = (hotWater: boolean, coldWater: boolean, heat: boolean): MeasurementType[] => {
  const measurementTypes: MeasurementType[] = [];
  if (hotWater) measurementTypes.push('HotWater');
  if (coldWater) measurementTypes.push('ColdWater');
  if (heat) measurementTypes.push('Heat');
  return measurementTypes;
};

interface IReportForm {
  locationId: string;
}

export const ReportForm = ({ locationId }: IReportForm): JSX.Element => {
  const { classes } = useStyles();
  const { mutateAsync: getGeneralReport, isLoading } = useGeneralReport();
  const { snackbarDispatch } = useContext(SnackbarContext);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm<IFormInput>({
    mode: 'onBlur',
    resolver: yupResolver(reportSchema),
    defaultValues: {
      hotWater: false,
      coldWater: false,
      heat: false,
      forDay: 1,
    },
  });

  const onSubmit: SubmitHandler<IFormInput> = async ({ forDay, hotWater, coldWater, heat }) => {
    if (isValid) {
      try {
        await getGeneralReport({
          forDay,
          locationId,
          measurementTypes: createMeasurementType(hotWater, coldWater, heat),
        });
      } catch (error) {
        snackbarDispatch({
          type: SnackbarActionType.SHOW,
          payload: {
            message: `Błąd podczas generowania raportu`,
            type: SnackbarType.ERROR,
          },
        });
      }

      reset();
    }
  };

  return (
    <form style={{ height: '100%' }} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
      <div className={classes.container}>
        <Typography variant="h3" align={'left'}>
          Raport miesięczny
        </Typography>
        <Typography variant="body1" className={classes.subtitle}>
          Wybierz parametry raportu, aby wygenerować szczegółowe dane dotyczące zużycia wody i energii za ostatnie 12
          miesięcy.
        </Typography>
        <Typography variant="h6" className={classes.section}>
          Wybierz dzień miesiąca:
        </Typography>
        <Tooltip
          placement={'top'}
          title="Dzień w miesiącu, na który zostanie wygenerowany raport.
              W przypadku gdy obecny miesiąc jest krótszy bądź dłuższy,
              niż podana wartość – system automatycznie dostosuje odpowiedni dzień, który będzie najbliżej tego wprowadzonego przez użytkownika"
        >
          <Controller
            name="forDay"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.forDay}
                helperText={errors.forDay && errors.forDay?.message && errors.forDay.message}
                id="month-day"
                type="number"
                label="Dzień miesiąca"
                InputProps={{
                  inputProps: { min: 1 },
                }}
                onKeyPress={(event) => {
                  if (event?.key === '-' || event?.key === '+') {
                    event.preventDefault();
                  }
                }}
              />
            )}
          />
        </Tooltip>
        <Typography variant="h6" className={classes.section}>
          Wybierz typ raportu:
        </Typography>
        <FormGroup>
          <FormControlLabel
            className={classes.section}
            control={
              <Controller
                name="hotWater"
                control={control}
                render={({ field }) => <Checkbox {...field} checked={field.value} />}
              />
            }
            label="Ciepła woda"
          />
          <FormControlLabel
            className={classes.section}
            control={
              <Controller
                name="coldWater"
                control={control}
                render={({ field }) => <Checkbox {...field} checked={field.value} />}
              />
            }
            label="Zimna woda"
          />
          <FormControlLabel
            className={classes.section}
            control={
              <Controller
                name="heat"
                control={control}
                render={({ field }) => <Checkbox {...field} checked={field.value} />}
              />
            }
            label="Ciepłomierz"
          />
        </FormGroup>
        <Button disabled={!isValid || isLoading} type="submit" sx={{ padding: 2 }} variant="contained">
          Generuj raport
        </Button>
      </div>
    </form>
  );
};
